import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Storage } from '@capacitor/storage';
import Tabs from "../views/Tabs.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/SignIn.vue"),

    meta: {
      authRequired: false,
    },
  },
  {
    path: "/logout",

    meta: {
      authRequired: false,
    },
    component: () => import("@/views/Logout.vue"),
  },
  {
    path: "/tabs/",
    component: Tabs,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "/start",
        component: () => import("@/views/Start.vue"),
      },
      {
        path: "welcome",
        component: () => import("@/views/VerifyDevice.vue"),
      },
      {
        path: "verify",
        component: () => import("@/views/VerifyDevice.vue"),
      },
      {
        path: "personal-information",
        component: () => import("@/views/Contact.vue"),
      },
      {
        path: "/verify-device",
        component: () => import("@/views/VerifyDevice.vue"),
      },
      {
        path: "/notification",
        component: () => import("@/views/Notification.vue"),
      },
      {
        path: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "share",
        component: () => import("@/views/Share.vue"),
      },
      {
        path: "coach",
        component: () => import("@/views/Coach.vue"),
      },
      {
        path: "about",
        component: () => import("@/views/About.vue"),
      },
      {
        path: "term",
        component: () => import("@/views/Term.vue"),
      },
      {
        path: "privacy-policy",
        component: () => import("@/views/Policy.vue"),
      },
      {
        path: "manage",
        component: () => import("@/views/Manage.vue"),
      },
      {
        path: "decks",
        component: () => import("@/views/ProgramNotification.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {

  const  currentState: any = {...store.state};
  const isAppLoading = store.getters['isLoading'] ;
  let isLoggedIn = false;

  if(Object.prototype.hasOwnProperty.call(currentState,'user')){
    isLoggedIn = currentState.user.isLoggedIn
  }

  //Check local storage for email address if not logged in already

  
  const getUserEmail = async () => {
    const { value } = await Storage.get({ key: 'userEmail' });
    return value;
  };
  if(!isLoggedIn){
     const userEmail = await getUserEmail();
     
    if(userEmail){
      store.dispatch("axLoginUser", {email:userEmail}).then (res =>{
       if(res){
         setTimeout( ()=>{router.push('/tabs/home');  store.commit("clearAppLoading");}, 1550)
         return next({path:'/tabs/home'})
       }
      })
    }

  }
 
   //Create TimeOut to make sure app doesn't get stuck loading
   setTimeout( ()=>{
     store.commit("clearAppLoading")
   }, 3000)
  if(isAppLoading && isLoggedIn){
    store.dispatch("getSubscribedCoach")
    store.dispatch("axSetActiveDeck")
  }

  if(to.fullPath =="/tabs/home"){
    store.dispatch("axSetActiveDeck")
  }

 
  if (to.matched.some(record => record.meta.authRequired)) {

    try {
      return isLoggedIn
        ? next()
        : next({
            path: "/",
          });
    } catch (e) {
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});
export default router;
