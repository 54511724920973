
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  // IonIcon,
  IonPage,
  IonRouterOutlet,
  alertController,
} from "@ionic/vue";

import { computed } from "vue";
import { Share } from "@capacitor/share";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    // IonIcon,
    IonPage,
    IonRouterOutlet,
  },

  setup() {
   // const router = useRouter();
    const store = useStore();

    const appBranding = computed(() => store.getters.getAppBranding);
    const subs = computed(() => store.getters.getActiveProgram);
    const programItems = computed(() => store.getters.getProgramItems);
    const currentUser = computed(() => store.getters.getCurrentUser);
    const currentCoach = computed(() => store.getters.getCoach);


    const activeCard = computed(()=> store.state.user.activeCard)
    const activeDeck = computed(() => store.state.subscription.activeDeck);
    

    const setCoach = store.dispatch("axSetAppBranding", {
      coachId: subs.value.coach.id,
    });

    const getMenuStyle = computed(() => {
      return `--background: ${appBranding.value.menuBackgroundColor};`;
    });
    const getTextStyle = computed(() => {
      return `--color: ${appBranding.value.menuTextColor};font-family:'${appBranding.value.fontFamily}'`;
    });
    const tabItems = computed(() => {
      return store.getters.getTabItems;
    });
    const shareCuratedContent2 = async (text: any) => {
      await Share.share({
        title: "TK2P",
        text,
        dialogTitle: "Share with friends.",
      });

    
    }
   const shareActiveCard = () =>{
     const cardId = activeCard.value.user.subscriptionItems.id
     const coachName = 'Levar Berry'
     const moreUrl = appBranding.value.moreUrl
     const txt = `Check out this program from ${coachName}: ${moreUrl}:${cardId}`
     shareCuratedContent2(txt)
   }
   const openUrl = () =>{
     if(!activeDeck.value.program.moreUrl || activeDeck.value.program.moreUrl ==''){
       alertController.create({message:"Nothing more defined for this deck."}).then ( ac => ac.present())
     }
     else {
       window.open(activeDeck.value.program.moreUrl,'_new')
     }
     
   }


    return {
      appBranding,
      subs,
      openUrl, 
      programItems,
      currentUser,
      setCoach,
      getMenuStyle,
      getTextStyle,
      currentCoach,
      activeCard,
      shareActiveCard,
      shareCuratedContent2,
      tabItems,
    };
  },
};
