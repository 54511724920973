import { apolloClient } from "@/vue-apollo"
import gql from "graphql-tag"


export default {
  getters: {
    getAppSettings: (state: { appSettings: any }) => state.appSettings
  },
  state: {
    appSettings: {
      showCode: false
    }
  },
  mutations: {
    setAppSettings(state: { appSettings: any }, payload: any) {
      
      state.appSettings = payload
    }
  },
  actions: {
    async axAppSettings({commit}: any) {
      
      const appSettingsRequest = await apolloClient.query({
        query: gql`
          query appSettings {
            appSettings {
              aboutUsTitle
              aboutUsDescription
              termsAndConditionsTitle
              termsAndConditionsDescription
              privacyPolicyTitle
              privacyPolicyDescription
              showCode
            }
          }
        `
      }) 

      if (appSettingsRequest.data) {
        
        commit("setAppSettings", appSettingsRequest.data.appSettings)
      }

    }
  }
}