import { SUBS_LOCAL_STORAGE_KEY } from "./subscription";

export function getSubsFromStorage(): any[] {
  try {
    return JSON.parse(localStorage.getItem(SUBS_LOCAL_STORAGE_KEY) || "[]");
  } catch (e) {
    return [];
  }
}
export function setNewSubToStorage(newSubCode: any) {
  try {
    if (getSubsFromStorage().length) {
      const newSubsList = [...getSubsFromStorage(), newSubCode];
      return localStorage.setItem(
        SUBS_LOCAL_STORAGE_KEY,
        JSON.stringify(newSubsList)
      );
    }
    
    return localStorage.setItem(
      SUBS_LOCAL_STORAGE_KEY,
      JSON.stringify([newSubCode])
    );
  } catch (e) {
    console.log(e)
    return false
  }
}


export function setSubsToStorage(subs: any[]) {
  try {
    
    localStorage.removeItem(SUBS_LOCAL_STORAGE_KEY)

    const dbEntry = subs.map(sub => {
      return {
        subId: sub.id,
        userId: sub.subscriber.id,
        coachId: sub.program.coach.id,
        code: sub.program.code,
        programId: sub.program.id,
        programName: sub.program.name,
      }
    })

    return localStorage.setItem(
      SUBS_LOCAL_STORAGE_KEY,
      JSON.stringify(dbEntry)
    )
 
  } catch (e) {
    console.log(e)
    return false
  }
}