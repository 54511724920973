import { createStore } from 'vuex'
//import VuexPersist from 'vuex-persist'
import subscription from './subscription'
import user from './user'
import app from './app'

// const vuexPersist = new VuexPersist({
//   storage: window.localStorage
// })
export default createStore({
  getters: {
    isLoading: state => state.isLoading
  },
  state: {
    isLoading: true,
    errors:  null,
    lastLaunchedAppOn: null,

  },
  mutations: {
    setAppError: function (state, payload) {
      state.errors = payload
    },
    clearAppError: function (state) {
      state.errors = null
    },
    setAppLoading: function (state) {
      state.isLoading = true
    },
    clearAppLoading: function (state) {
      state.isLoading = false
    }
  },
  modules: {
    subscription,
    user,
    app
  },
  plugins:[]
})
// plugins:[vuexPersist.plugin]