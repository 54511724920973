import { apolloClient } from "../vue-apollo";
import gql from "graphql-tag";
import { Storage } from '@capacitor/storage';

export default {
  getters: {
    getAppBranding: (state: any) => state.coachBranding,
    getCurrentUser: (state: any) => state.currentUser,
    getVerificationCode: (state: any) => state.verificationCode || "",
    getIsLoggedIn: (state: any) => state.isLoggedIn,
    getTabItems: (state: any) => state.tabItems,
  },
  state: {
    coachBranding: {},
    currentUser: {},
    playerId: null,
    verificationCode: null,
    isVerified: false,
    isLoggedIn: false,
    activeCard: {},
    tabItems: [
      {
        label: "Home",
        icon: "house-blank",
        href: "/tabs/home",
      },
      {
        label: "Share",
        icon: "paperclip",
        href: "/tabs/share",
      },
      {
        label: "Coach",
        icon: "user-large",
        href: "/tabs/coach",
      },
      {
        label: "Decks",
        icon: "memo",
        href: "/tabs/decks",
      },
      {
        label: "Settings",
        icon: "gear",
        href: "/tabs/manage",
      },
    ],
  },
  mutations: {
    setPlayerId(state: any, payload: any) {
      state.playerId = payload;
    },
    setAppBranding(state: any, payload: any) {
      state.coachBranding = payload;
    },
    setCurrentUser(state: any, payload: any) {
      state.currentUser = payload;
      state.isLoggedIn = true;
    },
    setTabItems(state: any, payload: any) {
      state.tabItems = payload;
    },
    setLogout(state: any) {
      state.currentUser = {};
      state.isLoggedIn = false;
    },
    setActiveCard(state: any, payload: any) {
      state.activeCard = payload;
    },
  },
  actions: {
    axPlayerId({ commit }: any, { playerId }: any) {
      commit("setPlayerId", playerId);
    },
    async axCreateUser({ commit, dispatch }: any, authForm: any) {
      commit("setAppLoading");

      await apolloClient
        .mutate({
          variables: {
            data: {
              firstName: authForm.firstName,
              lastName: authForm.lastName,
              email: authForm.email,
            },
          },
          mutation: gql`
            mutation createUser($data: UserInput!) {
              createUser(data: $data) {
                id
                email
              }
            }
          `,
        })
        .then(async (response) => {
          if (response) {
            await dispatch("axCreateSubscription", {
              userId: response.data.createUser.id,
              code: "DEFAULT",
            }).catch((err: any) =>
              console.log(err)
            );
            if (authForm.code && authForm.code.length > 5) {
              dispatch("axCreateSubscription", {
                userId: response.data.createUser.id,
                code: authForm.code,
              });
            }
            await dispatch("axSetCurrentUser", {
              userId: response.data.createUser.id,
            });
          }
        })
        .catch((error) => console.log(error));
    },
    async axUpdateUser(
      { dispatch }: any,
      { id, firstName, lastName, email, mobile, pushId }: any
    ) {
      const nId = parseFloat(id)
      const response = await apolloClient.mutate({
        variables: {
          id:nId,
          data: {
            firstName,
            lastName,
            email,
            mobile,
            pushId,
          },
        },
        mutation: gql`
          mutation updateUser($id: Float!, $data: UserInput!) {
            updateUser(id: $id, data: $data) {
              id
            }
          }
        `,
      });

      if (response && response.data) {
        dispatch("axSetCurrentUser", { userId: id });
      }
    },
    async axSetCurrentUser({ commit, dispatch }: any, { userId }: any) {
      const response = await apolloClient.query({
        variables: {
          id: parseInt(userId),
        },
        query: gql`
          query getUserData($id: Float!) {
            user(id: $id) {
              id
              firstName
              lastName
              mobile
              email
              activeProgramId
              pushId
            }
          }
        `,
        fetchPolicy: "network-only",
      });
      commit("setCurrentUser", response.data.user);

      if (response.data.user.activeProgramId) {
        dispatch("axSetActiveProgram", {
          programId: response.data.user.activeProgramId,
        });
      }
    },
    async axSetActiveProgram({ commit, dispatch }: any, { programId }: any) {
      const response = await apolloClient.query({
        variables: {
          programId: parseInt(programId),
        },
        fetchPolicy: "network-only",
        query: gql`
          query program($programId: Float!) {
            program(programId: $programId) {
              id
              name
              coach {
                id
                firstName
                lastName
                email
                coachLogo
              }
            }
          }
        `,
      });
      commit("setActiveProgram", response.data.program);
      dispatch("axSetAppBranding", { coachId: response.data.program.coach.id });
      dispatch("axSetActiveDeck");
    },
    async axSetActiveDeck({ state, commit }: any) {
      const programId = state.currentUser.activeProgramId;
      const userId = state.currentUser.id;
      const response = await apolloClient.query({
        variables: {
          programId: parseInt(programId),
          userId: parseInt(userId),
        },
        query: gql`
          query userSubscription($programId: Float!, $userId: Float!) {
            userSubscription(programId: $programId , userId: $userId) {
              id status
              program{
                id moreUrl
              }
              subscriptionItems{
                
                id createdAt status
                programItem{
                  id 
                  name 
                  graphic 
                  logoUrl 
                  fontSize 
                  text 
                  backgroundColor
                  fontSize
                  fontColor
                  fontFamily
                  showLogo
                  order 
                  logoPositionX 
                  logoPositionY
                  textPositionX
                  textPositionY
                }
              }
            }
          }
        `,
        fetchPolicy: "network-only",
      });
      //Order Subscription Items by date
      const orderedItems = response.data.userSubscription.subscriptionItems.sort(
        (a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1)
      );
      response.data.userSubscription.subscriptionItems = orderedItems;
      commit("setActiveDeck", response.data.userSubscription);
    },
    async axSetAppBranding({ commit, dispatch }: any, { coachId }: any) {
      const id = parseFloat(coachId);
      const { data } = await apolloClient.query({
        query: gql`
          query CoachBranding($id: Float!) {
            CoachBranding(id: $id) {
              id
              menuBackgroundColor
              menuTextColor
              fontFamily
              fontSize
              contentColor
              welcomeMessage
              contactInfoName
              contactInfoHeader
              contactInfoPhoto
              contactInfoIntroduction
              contactInfoEmail
              contactInfoTwitter
              contactInfoYoutube
              contactInfoFacebook
              contactInfoInstagram
              contactInfoLinkedIn
              nameInMainMenu
            }
          }
        `,
        fetchPolicy: "network-only",
        variables: {
          id,
        },
      });

      commit("setAppBranding", data.CoachBranding);
      dispatch("axTabItems");
    },
    async axLoginByPass({ dispatch }: any) {
      dispatch("loadCurrentUserData", { userId: 1 });
    },
    async axLoginUser({ dispatch }: any, { email }: any) {
      const response = await apolloClient.query({
        variables: {
          email,
        },
        query: gql`
          query loginUser($email: String!) {
            loginUser(email: $email) {
              status
              user {
                id
                firstName
                lastName
                mobile
                email
              }
            }
          }
        `,
      });

      if (response.data.loginUser.status === 'success') {
        await dispatch("axSetCurrentUser", { userId: response.data.loginUser.user.id });
        // dispatch("ax")
        return true;
      } else {
        return false;
      }
    },

    async axLogout({ commit }: any) {
      window.localStorage.clear();
      await Storage.clear();
      commit("setLogout");
    },

    async axActiveCard({ commit }: any, payload: any) {
      commit("setActiveCard", payload);
    },
    async axTabItems({ commit, state }: any) {
      const tabItems = [
        {
          label: "Home",
          icon: "house-blank",
          href: "/tabs/home",
        },
        {
          label: "More",
          icon: 'paperclip',
          href: "/tabs/share",
        },
        {
          label:
            (state.coachBranding && state.coachBranding.nameInMainMenu) ||
            "Coach",
          icon: "user-large",
          href: "/tabs/coach",
        },
        {
          label: "Decks",
          icon: "memo",
          href: "/tabs/decks",
        },
        {
          label: "Settings",
          icon: "gear",
          href: "/tabs/manage",
        },
      ];

      commit("setTabItems", tabItems);
    },
  },
};
