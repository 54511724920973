
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'



// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri:  process.env.VUE_APP_GRAPHQL_HTTP || 'https://dev.api.tk2p.com',
  //uri:  process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4003/',
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})


