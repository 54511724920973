import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./index.css";
import "./assets/styles/animatedbg.css"
import store from "./store";

import { apolloClient } from "./vue-apollo";
import OneSignal from 'onesignal-cordova-plugin';

// Call this function when your app starts
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE  
  // OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId("bd6daf0c-d3ff-421a-9cdd-a6cc44b92e5b");
  OneSignal.setNotificationOpenedHandler(function(jsonData: any) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted: any) {
      console.log("User accepted notifications: " + accepted);
  });
 OneSignal.addSubscriptionObserver( (state)=> {
   console.log(state, "SOTU")
    if (!state.from.isSubscribed && state.to.isSubscribed) {
      console.log("Subscribed for OneSignal push notifications!")
      // get player ID
      if(state.to.userId)
       (window as any)['oneSignalId']  = state.to.userId //this.db.setPlayerId(state.to.userId)
    }
   // alert("Push Subscription state changed: " + JSON.stringify(state));
  });
  
}


const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),

})
.use(store)
.use(IonicVue)
.use(router);

router.isReady().then(() => {
  app.mount("#app");
  OneSignalInit();
});

document.addEventListener("deviceready", OneSignalInit, false);
