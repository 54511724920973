<template>
  <ion-app>
    <ion-grid v-if="isLoading">
      <ion-row>
        <ion-col size="12">
          <h4>Loading...</h4>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-router-outlet v-if="!isLoading" />
    <div></div>
  </ion-app>
</template>
<style scoped>
h4 {
  text-align: center;
}
ion-grid {
  width: 100%;
}
ion-row {
  height: 100%;
  align-content: center;
}
</style>
<script>
import {
  IonApp,
  IonRouterOutlet,
  IonRow,
  IonGrid,
  IonCol,
  IonProgressBar,
} from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import "./index.css";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonRow,
    IonGrid,
    IonCol,
    IonProgressBar,
  },
  setup() {
    const store = useStore();

    return {
      isLoading: computed(() => store.getters.isLoading),
    };
  },
});
</script>
