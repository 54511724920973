import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, {
            style: _normalizeStyle($setup.getMenuStyle),
            slot: "bottom"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabItems, (item, key) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                  (item.label !='More')
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 0,
                        style: _normalizeStyle($setup.getTextStyle),
                        tab: `tab${key}`,
                        href: item.href
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(`fa-light fa-${item.icon} fa-2x`)
                          }, null, 2),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["style", "tab", "href"]))
                    : _createCommentVNode("", true),
                  (item.label =='More')
                    ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                        key: 1,
                        style: _normalizeStyle($setup.getTextStyle),
                        tab: `tab${key}`,
                        onClick: ($event: any) => ($setup.openUrl(item))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(`fa-light fa-${item.icon} fa-2x`)
                          }, null, 2),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["style", "tab", "onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          }, 8, ["style"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}