import { apolloClient } from "../vue-apollo";
import { customAlphabet } from 'nanoid'
import gql from "graphql-tag";
import {
  getSubsFromStorage,
  setSubsToStorage,
} from "./getSubsFromStorage";
import { sendVerificationCode, checkVerificationCode, validateProgramCode } from '@/mixins/gql/verfiy'
import { compileTemplate } from "@vue/compiler-sfc";

export const SUBS_LOCAL_STORAGE_KEY = "user-subs";

export default {
  getters: {
    getCoachId: (state: any) =>
      (state.subscribedCoach && state.subscribedCoach.id) ||
      getSubsFromStorage()[0].coachId,
    getProgramItems: (state: any) => state.subscribedProgramItems,
    getCoach: (state: any) => state.subscribedCoach,
    getUserSubscriptions: (state: any) => state.userSubscriptions,
    getCurrentCuratedContent: (state: any) => state.currentCuratedContent,
    getActiveProgram: (state: any) => state.activeProgram
  },
  state: {
    // coach: null,
    subscribedCoach: {},
    subscribedProgramItems: [],
    coachBranding: {},
    currentUser: {},
    activeProgram: {},
    activeDeck: { id: 0 },
    activeIndex: 0,
    userSubscriptions: [],
    currentCuratedContent: {},
    verificationCode: null
  },
  mutations: {
    userSubscribedCoachData(state: any, payload: any) {
      state.subscribedCoach = payload;
    },
    setCoachBranding(state: any, payload: any) {
      state.coachBranding = payload;
    },
    setSubscribedProgramItems(state: any, payload: any) {
      state.subscribedProgramItems = payload;
    },
    setCurrentCuratedContent(state: any, payload: any) {
      state.currentCuratedContent = payload;
    },
    setCurrentUser(state: any, payload: any) {
      state.currentUser = payload;
    },
    setUserSubscriptions: function (
      state: { userSubscriptions: any },
      payload: any
    ) {
      state.userSubscriptions = payload;
    },
    setVerificationCode: function (
      state: any,
      payload: any
    ) {
      state.verificationCode = payload;
    },
    setActiveProgram: function (
      state: any,
      payload: any
    ) {
      state.activeProgram = payload;
    },
    setActiveDeck: function (
      state: any,
      payload: any
    ) {
      state.activeDeck = payload;
    },
    setActiveIndex: function (
      state: any,
      payload: any
    ) {
      state.activeIndex = payload;
    },
  },
  actions: {
    async axUpdateSubscription(_: any, { playerId, subId }: any) {
      await apolloClient.mutate({
        variables: {
          id: subId,
          data: {
            deviceId: playerId
          }
        },
        mutation: gql`
          mutation updateDeviceId($id: Float!, $data: SubscriptionUpdateInput!) {
            updateDeviceId (id: $id, data: $data) {
              id
            }
          }
        `
      })
    },
    async axUpdateShare(
      _: any,
      { userId, programItemId }: any
    ) {

      await apolloClient.mutate({
        variables: {
          userId: parseInt(userId),
          programItemId: parseInt(programItemId)
        },
        mutation: gql`
          mutation shareProgramItem($userId: Float!, $programItemId: Float!) {
            shareProgramItem(userId: $userId, programItemId: $programItemId) {
              id
              numShares
            }
          }
        `
      }).catch(error => console.log(error))

    },
    async axUpdateActiveIndex( {commit}: any, { index }: any){
      commit("setActiveIndex", index)
    },
    async axSaveCurrentUserSubscription(
      { commit }: any,
      { subscription }: any
    ) {
      commit("setUserSubscription", subscription);
    },
    async axUserSubscriptions({ commit, state }: any) {

      // const subs = getSubsFromStorage();
      // debugger
      const response = await apolloClient
        .query({
          fetchPolicy: "network-only",
          variables: {
            userId: parseInt(state.currentUser.id),
          },
          query: gql`
            query userSubscriptions($userId: Float!) {
              userSubscriptions(userId: $userId) {
                id

                subscriber {
                  id
                  firstName
                  lastName
                }
                program {
                  name
                  code
                  name
                  id
                  status
                  coach {
                    id
                    firstName
                    lastName
                    coachLogo
                  }
                }
              }
            }
          `,
        
        })
        .catch((error) => console.log(error));
      if (response) {


        commit("setUserSubscriptions", response.data.userSubscriptions);
        setSubsToStorage(response.data.userSubscriptions);
      }
    },

    async getSubscribedCoach({ commit, state }: any) {
      

      // const id = state.subscribedCoach && state.subscribedCoach.id || state.currentSubcriptions[0].id
      const id = state.activeProgram.coach.id;
      const results = await apolloClient
        .query({
          variables: {
            id,
          },
          query: gql`
            query getCoach($id: String!) {
              coach(id: $id) {
                id
                firstName
                lastName
                coachLogo
                coachLinks {
                  id
                  url
                  linkType
                  label
                }
              }
            }
          `,
          fetchPolicy:"network-only"
        })
        .catch((errors) => commit("setAppError", errors, { root: true }));

      if (!results) return;

      commit("userSubscribedCoachData", results.data.coach);
    },
    async axCreateSubscription(
      { commit, dispatch }: any,
      payload: any
    ) {
  
      const vars: any = {};

      vars.data = {
        userId: payload.userId || payload.id,
        code: payload.code

      };

      const response = await apolloClient
        .mutate({
          variables: vars,
          mutation: gql`
            mutation createSubscription(
              $data: SubscriptionInput!
            ) {
              createSubscription(data: $data) {
                id
                subscriber {
                  id
                  firstName
                  lastName
                }
                program {
                  code
                  name
                  id
                  coach {
                    id
                    firstName
                    lastName
                    coachLogo
                  }
                }
              }
            }
          `,
        })
        .catch((errors) => commit("setAppError", errors, { root: true }));

      if (!response.data) return;

      if (response.errors) {
        commit("setAppError", response.errors, { root: true });
        return;
      }
      const data = response.data

      dispatch("axSetActiveProgram", { programId: data.createSubscription.program.id });
      dispatch("axUserSubscriptions")

    },

    async axDeleteSubscription({ dispatch }: any, { subId }: any) {
      const response = await apolloClient.mutate({
        variables: {
          id: subId,
        },
        mutation: gql`
          mutation deleteSubscription($id: String!) {
            deleteSubscription(id: $id) {
              id
            }
          }
        `,
      });

      if (response) {
        dispatch("axUserSubscriptions");
      }
    },
    async axToggleSubscription({ state, dispatch }: any, { programId }: any) {
      const userId = parseInt(state.currentUser.id)
      const response = await apolloClient.mutate({
        variables: {
          id: userId,
          data: {
            activeProgramId: parseInt(programId) 
          }
        },
        mutation: gql`
          mutation updateUser($id: Float!, $data: UserInput!) {
            updateUser (id: $id, data: $data) {
              id  
            }
          }
        `,
      });

      if (response) {
        // @todo: redirect user. This
       await dispatch("axSetCurrentUser", {userId});
      //  await dispatch("axUserSubscriptions");
      await dispatch("getSubscribedCoach");
      
      //  await dispatch("axSetActiveProgram", {programId});
      //  await dispatch("axSetActiveDeck")
      //  console.log(state.activeDeck)
      }
    },
    async axSendVerificationCode({ commit, state }: any, { via }: any) {
      const nanoid = customAlphabet('1234567890abcdef', 10)
      commit('setVerficiationCode', nanoid)
      const response = await apolloClient.query({
        variables: {
          code: nanoid,
          via,
          req: state.currentUser.email
        },
        query: sendVerificationCode
      });
      console.log(response)
    },
    async axCheckVerificationCode({ commit, state }: any, { via }: any) {
      commit('setIsVerified', null)
      const response = await apolloClient.query({
        variables: {
          code: state.verificationCode,
          via,
          req: state.currentUser.email
        },
        query: checkVerificationCode
      });
      if (response.data.checkVerificationCode == "success") {
        commit('setIsVerified', true)
      }
      else {
        commit('setIsVerified', false)
      }

    },
    async axValidateProgramCode({ state }: any, { code }: any) {
      console.log(state.activeDeck)
      const response = await apolloClient.query({
        variables: {
          code
        },
        query: validateProgramCode
      });
      if (response.data.validateProgramCode == "success") {
       return true
      }
      else {
       return false
      }
   

    }
  },
};
