import gql from "graphql-tag";

export const userSubscriptions = gql` 
query userSubscriptions($userId: Float!) {
    userSubscriptions(userId: $userId) {
      id
      subscriber {
        id
        firstName
        lastName
      }
      program {
        name
        code
        name
        id
        coach {
          id
          firstName
          lastName
          coachLogo
        }
      }
    }
  }`

export const sendVerificationCode = gql` 
query sendVerificationCode($code: String!, $via: String!, $req:  String!) {
    sendVerificationCode(code: $code, via: $via, req: $req) 
  }`

export const checkVerificationCode = gql` 
query checkVerificationCode($code: String!, $salt:  String!) {
    sendVerificationCode(code: $code, salt: $salt) 
  }`

export const validateProgramCode = gql` 
query validateProgramCode($code: String!) {
  validateProgramCode(code: $code) 
  }`